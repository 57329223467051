import { Tool } from "./tool";
import { scene, store, state } from "../store";
import { kernel } from "../renderer/objects/kernel";
import { rerender } from "../renderer/renderer";
import { wave } from "../renderer/objects/wave";

const WaveTool: Tool = {
    name: '(W)ave Filter',
    icon: 'blur_on',
    shortcut: 'w',
    onSelect: () => {
        const obj = wave.create();
        scene.children.push(obj);
        store.object = obj.id;
        state.object = obj;
        rerender();
        return false;
    }
};

export { WaveTool };
