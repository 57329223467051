import { Tool } from "./tool";
import { scene, store, state } from "../store";
import { file } from "../renderer/objects/file";
import { rerender } from "../renderer/renderer";
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { threescene } from "../renderer/three";
import { Potrace } from "../potrace";
import { line } from "../renderer/objects/line";

const FileTool: Tool = {
    name: '(F)ile',
    icon: 'image',
    shortcut: 'f',
    onSelect: () => {
        const fi = document.createElement('input');
        document.body.appendChild(fi);
        fi.setAttribute('type', 'file');
        fi.setAttribute('accept', '.png,.jpg,.jpeg,.bmp,.gif,.obj');
        fi.addEventListener('change', () => {
            let filename = fi.value.split('.');
            let extension = filename[filename.length-1];

            var reader = new FileReader();
            reader.addEventListener("load", function () {
                if (extension === 'obj') {
                    var loader = new OBJLoader();
                    loader.load(reader.result as string, function ( obj ) {
                        console.log(obj);
                        // (obj.children[0] as any).geometry.computeVertexNormals();
                        threescene.add(obj);
                    }, undefined, function ( error ) {
                        console.error( error );
                    } );
                } else {
                    const img = new Image();
                    img.onload = () => {
                        const obj = file.create({
                            data: reader.result,
                            translate: [img.width/2, img.height/2],
                            dimensions: [img.width, img.height],
                            anchor: [0.5, 0.5],
                        });
                        scene.children.push(obj);
                        state.object = obj;
                        store.object = obj.id;
                        document.body.removeChild(fi);


                        // Potrace.loadImageFromUrl(reader.result)
                        // Potrace.setParameter({turdsize: 1, optcurve: false});
                        // Potrace.process(() => {
                        //     const d = Potrace.getSVG(1, undefined).match(/d="M(.+?)"/)[1];
                        //     console.log(d);
                        //     const l = d.split('M');
                        //     for (var ln of l) {
                        //         const lnobj = line.create({strokeWidth: 0, fillColor: 'black'});
                        //         const p = ln.split(/[,\s]/);
                        //         for (var i=0; i<p.length; i++) {
                        //             if (i === 0 || p[i] === 'C') {
                        //                 var x = Number(p[i+1]);
                        //                 var y = Number(p[i+2]);
                        //                 lnobj.points.push([x,y]);
                        //             }
                        //         }
                        //         scene.children.push(lnobj);
                        //     }
                            
                        //     rerender();
                        // })

                        rerender();
                    };
                    img.src = reader.result as string;
                }
            }, false);
            if (fi.files[0]) {
                reader.readAsDataURL(fi.files[0]);
            }
        });
        fi.click();
        return false;
    }
};

export { FileTool };
