import * as React from "react";
import { theme } from "../../theme";
import styled from "styled-components";
import { Item } from "../atoms/Item";
import { tools } from "../../tools/index";
import { scene, store, state } from "../../store";
import { observer } from "mobx-react";
import { ListArea, InputHolder, Input } from "../atoms/ObjectTree";
import { propTypes } from "../../renderer/objects/object";
import { rerender } from "../../renderer/renderer";
import { objectTypes } from "../../renderer/objects";

const fieldTypes: {[key: string]:string} = {
    'boolean': 'text',
    'number': 'text',
};

@observer
class PropertyPanel extends React.Component {
    state = {
        search: ''
    }
    componentWillUnmount() {
        store.picker = false;
    }
    render() {
        const { search } = this.state;
        const settings = store.toolsettings && state.tool.settings ? state.tool.settings : (state.object || {});
        const Controls = state.object && objectTypes[state.object.type].controls;
        return <Main>
            <InputHolder>
                <Input
                    id="propertysearch"
                    placeholder="(P)roperties..."
                    value={search}
                    onChange={(e) => {
                        this.setState({ search: e.currentTarget.value });
                    }}
                    onKeyUp={(e) => {
                        if (e.key === 'Escape') {
                            this.setState({ search: '' });
                            e.currentTarget.blur();
                        }
                    }}
                />
            </InputHolder>
            <ListArea>
                {Controls && <Controls props={state.object} />}
                {store.toolsettings && <Column
                    style={{color: 'white', background: 'rgb(32,32,32)', textAlign: 'right', cursor: 'pointer', display: 'block', height: '16px', lineHeight: '16px'}}
                    onClick={() => store.toolsettings = null}
                >Close Tool Settings</Column>}
                {Object.keys(settings).filter((k) => {
                    return !search || k.toLowerCase().indexOf(search.toLowerCase()) !== -1;
                }).map((k: any, i: number) => {
                    return <PropRow key={i} i={i} k={k} settings={settings} setSearch={(search: string) => this.setState({search})} />
                })}
            </ListArea>
        </Main>;
    }
}

const PropRow = observer(({i, k, settings, setSearch}) => {
    if (['data','points','children','pixels'].indexOf(k) !== -1 || k.startsWith('_')) return null;
    return <Row key={i}>
        <Column>{k}</Column>
        <Column>
            {
                (
                    !propTypes[k] &&
                    JSON.stringify(settings[k])
                ) || (
                    propTypes[k].isArray &&
                    settings[k].map((arrEl: any, j: number) => {
                        return <Input key={j} value={arrEl}
                            onFocus={() => {
                                store.property = k;
                                store.propertyIndex = j;
                                if (k.toLowerCase().indexOf('color') !== -1 || k === 'palette') store.picker = true;
                            }}
                            onBlur={() => {
                                store.picker = false;
                            }}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    setSearch('');
                                    e.currentTarget.blur();
                                }
                            }}
                            onChange={(ele) => {
                                const p = propTypes[k];
                                settings[k][j] = ele.target.value;
                                rerender();
                            }}
                        />
                    })
                ) || (
                    propTypes[k].options &&
                    <select value={settings[k]} onChange={(e) => {
                        settings[k] = e.target.value;
                        store.redraw++;
                    }}>
                        {propTypes[k].options.map((v: any, i: number) => {
                            return <option key={i}>{v}</option>
                        })}
                    </select>
                ) || (
                    <Input 
                        type={fieldTypes[propTypes[k].type] || 'text'}
                        value={settings[k]}
                        checked={settings[k]}
                        onFocus={() => {
                            store.property = k;
                            if (k.toLowerCase().indexOf('color') !== -1) store.picker = true;
                        }}
                        onBlur={() => {
                            store.picker = false;
                        }}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                setSearch('');
                                e.currentTarget.blur();
                            }
                        }}
                        onChange={(e) => {
                            const p = propTypes[k];
                            if (p.type === 'boolean') {
                                settings[k] = e.target.checked;
                            } else {
                                settings[k] = e.target.value;
                            }
                            if (p.uncache) {
                                for (const e in settings) {
                                    if (e[0] === '_') {
                                        settings[e] = null;
                                    }
                                } 
                            }
                            rerender();
                        }}
                    />
                )
            }
        </Column>
    </Row>
});

const Main = styled.div`
    position: relative; flex: 1;
    background: ${theme.paneColor};
    display: flex; flex-direction: column;
    overflow: hidden;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    overflow: hidden;
    min-height: 40px;
`;

const Column = styled.div`
    margin: 1px;
    line-height: 24px;
    padding: 10px;
    display: flex;
    user-select: none;
    background: white;
    flex: 1; flex-basis: 0;
    display: flex;
`;

export { PropertyPanel };
