import styled, { ThemedStyledInterface } from "styled-components";

const theme = {
    paneColor: 'rgb(32,32,32)',
    bgColor: 'rgb(64,64,64)',
    highlighted: 'rgb(100,255,200)',
    smallWidth: 1200,
}

const styled2 = styled;// as ThemedStyledInterface<typeof theme>;

export { theme, styled2 as styled };
