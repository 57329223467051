import { SceneObject, uniqueId, baseProps } from "./object";
import { rect } from "./rect";
const QRCode = require('qrcode');

const qr: SceneObject = {
    create: (props: any) => {
        return {
            id: 'QR ' + uniqueId(),
            type: 'qr',
            ...baseProps(),
            content: 'https://creator.dawpler.com',
            ...props
        };
    },
    render: async (cty, data) => {
        if (data._last === data.content) {
            if (data._qr && data._qr.width) {
                cty.drawImage(data._qr, 0, 0);
            }
            return;
        }

        console.log('Recreating QR Code');
        const canvas = data._qr = data._qr || document.createElement('canvas');
        data._last = data.content;
        let qr = await QRCode.create(String(data.content) || 'https://www.youtube.com/watch?v=dQw4w9WgXcQ');
        const qrsize = qr.modules.size;
        const qrdata = [...qr.modules.data];
        const qrmult = 10;
        const xOff = 0;
        const yOff = 0;
        const rad = 5;
        const srad = 5;
        const light = 'rgb(255,255,255,0.7)';
        const dark = 'rgb(0,0,0,0.7)';
        const lightBorder = 'rgb(255,255,255,0.3)';
        const darkBorder = 'rgb(0,0,0,0.3)';

        canvas.width = canvas.height = qrmult * (qrsize+2);
        const ctx = canvas.getContext('2d');

        const qrcenter = qrmult/2;
        const qrcenteroff = qrmult/4;
        const square = (x: number, y: number) => {
            if (data.lightDots) {
                rect.render(ctx, rect.create({
                    x: xOff+x+(qrmult/2), y: yOff+y+(qrmult/2), width: 8*qrmult, height: 8*qrmult, radius: rad,
                    fillColor: light,
                }));
            }
            rect.render(ctx, rect.create({
                x: xOff+x+qrmult, y: yOff+y+qrmult, width: 7*qrmult, height: 7*qrmult, radius: rad,
                fillColor: dark,
            }));
            if (data.lightDots) {
                rect.render(ctx, rect.create({
                    x: xOff+x+qrmult*2, y: yOff+y+qrmult*2, width: 5*qrmult, height: 5*qrmult, radius: rad,
                    fillColor: light,
                }));
            } else {
                ctx.save();
                ctx.globalCompositeOperation = 'destination-out';
                rect.render(ctx, rect.create({
                    x: xOff+x+qrmult*2, y: yOff+y+qrmult*2, width: 5*qrmult, height: 5*qrmult, radius: rad,
                    fillColor: 'black',
                }));
                ctx.restore();
            }
            rect.render(ctx, rect.create({
                x: Number(xOff+x+qrmult*3), y: Number(yOff+y+qrmult*3), width: 3*qrmult, height: 3*qrmult, radius: rad,
                fillColor: dark,
            }));
        }
        square(0, 0);
        square((qrsize-7)*qrmult, 0);
        square(0, (qrsize-7)*qrmult);

        qrdata.forEach((p, i) => {
            const x = (i%qrsize)+1;
            const y = Math.floor(i/qrsize)+1;
            if (
                x < 9 && y < 9 ||
                x < 9 && y > qrsize-8 ||
                x > qrsize-8 && y < 9
            ) return;

            if (p) {
                rect.render(ctx, rect.create({
                    x: xOff+x*qrmult,
                    y: yOff+y*qrmult,
                    width: qrmult,
                    height: qrmult,
                    radius: srad,
                    fillColor: darkBorder,
                }));
                ctx.fillStyle = dark;
                rect.render(ctx, rect.create({
                    x: xOff+x*qrmult+qrcenteroff,
                    y: yOff+y*qrmult+qrcenteroff,
                    width: qrcenter,
                    height: qrcenter,
                    radius: srad,
                    fillColor: dark,
                }));
            } else if (data.lightDots) {
                rect.render(ctx, rect.create({
                    x: xOff+x*qrmult,
                    y: yOff+y*qrmult,
                    width: qrmult,
                    height: qrmult,
                    radius: srad,
                    fillColor: lightBorder,
                }));
                rect.render(ctx, rect.create({
                    x: xOff+x*qrmult+qrcenteroff,
                    y: yOff+y*qrmult+qrcenteroff,
                    width: qrcenter,
                    height: qrcenter,
                    radius: srad,
                    fillColor: light,
                }));
            }
        });
    }
};

export { qr };
