import { Tool } from "./tool";
import { scene, store, state } from "../store";
import { qr } from "../renderer/objects/qr";
import { rect } from "../renderer/objects/rect";
import { rerender } from "../renderer/renderer";

const RectTool: Tool = {
    name: '(R)ectangle',
    icon: 'crop_square',
    shortcut: 'r',
    onMouseDown: () => {
        const rc = rect.create({
            translate: [...store.canvasPosition],
            width: 1, height: 1
        });
        scene.children.push(rc);
        store.object = rc.id;
        state.object = rc;
        rerender();
    },
    onMouseMove: ([x, y]: number[]) => {
        if (!store.dragging) return;
        state.object.width += x*scene.scale;
        state.object.height += y*scene.scale;
        state.object.width = Math.max(1, state.object.width);
        state.object.height = Math.max(1, state.object.height);
        rerender();
    }
};

export { RectTool };
