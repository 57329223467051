import { Tool } from "./tool";
import { state, store, scene } from "../store";
import { rerender } from "../renderer/renderer";

const ScaleTool: Tool = {
    name: 'Scale (n)',
    icon: 'fullscreen',
    shortcut: 'n',
    onMouseMove: (delta: number[]) => {
        if (state.object && state.object.scale) {
            if (store.dragging) {
                state.object.scale[0] = (Number(state.object.scale[0]) || 0) + ((delta[0]*scene.scale/500));
                state.object.scale[1] = (Number(state.object.scale[1]) || 0) + ((delta[1]*scene.scale/500));
                rerender();
            }
        }
    }
};

export { ScaleTool };
