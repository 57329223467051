import * as React from "react";
import styled from "styled-components";

const InputHolder = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 1px;
`;

const Input = styled.input`
    position: relative;
    line-height: 20px;
    flex: 1;
    width: 50px;
    border: 0px;
    outline: none;
    padding: 5px;
`;

const ListArea = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
`;

export { InputHolder, Input, ListArea };
