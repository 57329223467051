import { MoveTool } from "./move";
import { DrawTool } from "./draw";
import { DrawPixelsTool } from "./drawpixels";
import { Tool } from "./tool";
import { QRTool } from "./qr";
import { FileTool } from "./file";
import { RectTool } from "./rect";
import { SaveSceneTool } from "./savescene";
import { LoadSceneTool } from "./loadscene";
import { BlurTool } from "./blur";
import { OutlineTool } from "./outline";
import { SharpenTool } from "./sharpen";
import { LevelsTool } from "./levels";
import { ScaleTool } from "./scale";
import { RotateTool } from "./rotate";
import { PixelArtTool } from "./pixelart";
import { ExportTool } from "./export";
import { WaveTool } from "./wave";
import { GeometryTool } from "./geometry";
import { SettingsTool } from "./settings";
import { PointsTool } from "./points";

const tools: Tool[] = [
    MoveTool,
    ScaleTool,
    RotateTool,
    PointsTool,
    DrawTool,
    RectTool,
    FileTool,
    BlurTool,
    SharpenTool,
    OutlineTool,
    WaveTool,
    LevelsTool,
    PixelArtTool,
    DrawPixelsTool,
    QRTool,
    GeometryTool,
    SettingsTool,
    SaveSceneTool,
    LoadSceneTool,
    ExportTool,
];

export { tools };
