import { SceneObject, uniqueId, baseProps } from "./object";
import { store } from "../../store";

const levels: SceneObject = {
    create: (props: any) => {
        return {
            id: 'Levels ' + uniqueId(),
            type: 'levels',
            low: 0,
            high: 255,
            ...props
        };
    },
    render: async (ctx, data, good) => {
        var scale = 255/(data.high-data.low);

        var d = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height).data;
        var sz = ctx.canvas.width*ctx.canvas.height*4;
        for (var i=0; i<sz; i+=4) {
            d[i] = scale*(d[i]-data.low);
            d[i+1] = scale*(d[i+1]-data.low);
            d[i+2] = scale*(d[i+2]-data.low);
        }
        ctx.putImageData(new ImageData(d, ctx.canvas.width, ctx.canvas.height), 0, 0);
    }
};

export { levels };
