import { Tool } from "./tool";
import { scene, store, state } from "../store";
import { levels } from "../renderer/objects/levels";
import { rerender } from "../renderer/renderer";

const LevelsTool: Tool = {
    name: '(L)evels',
    icon: 'timeline',
    shortcut: 'l',
    onSelect: () => {
        const obj = levels.create({});
        scene.children.push(obj);
        store.object = obj.id;
        state.object = obj;
        rerender();
        return false;
    }
};

export { LevelsTool };
