import * as math from "mathjs";
import * as THREE from 'three';
import { objectTypes } from "./objects";
import { scene, store } from "../store";
import { canvases } from "../components/organisms/WorkArea";
import { propTypes } from "./objects/object";
import { threerenderer, threecamera, threescene } from "./three";

function rerender() {
    store.redraw++;
}

let lastUpdate = Date.now();
function rerender2() {
    if (canvases && !store.rendering) {
        const fps = document.getElementById('fps');
        if (fps) fps.innerText = String(Math.floor(1000/(Date.now()-lastUpdate)));
        lastUpdate = Date.now();
        if (store.playing && !store.rendering) {
            store.frame = ((Date.now() - store.playingStart) * 30 / 1000) % 240;
        }
        let last: any = {
            radius: 0,
            strokeWidth: 5,
            color: "rgba(0,150,100,1)",
            fillColor: "rgba(0,0,0,0)",
            style: "simple",
        };

        for (const c in canvases) {
            if (canvases[c]) {
                canvases[c].style.width = `${scene.dimensions[0]}px`;
                canvases[c].style.height = `${scene.dimensions[1]}px`;
                canvases[c].setAttribute('width', scene.dimensions[0]);
                canvases[c].setAttribute('height', scene.dimensions[1]);
            }
        }
        const aspect = scene.dimensions[0] / scene.dimensions[1];
        if (aspect !== threecamera.aspect) {
            threecamera.aspect = scene.dimensions[0] / scene.dimensions[1];
            threecamera.updateProjectionMatrix();
        }
        threerenderer.setSize( scene.dimensions[0], scene.dimensions[1] );

        if (canvases['2d']) {
            const ctx = canvases['2d'].getContext('2d');
            ctx.clearRect(0, 0, scene.dimensions[0], scene.dimensions[1]);
            renderChildren(ctx, scene.children, last);
        }
        if (!isNaN(Number(scene.backgroundColor))) {
            threescene.background = new THREE.Color(scene.palettes[0][Number(scene.backgroundColor)]);
        }
        threerenderer.render( threescene, threecamera );
        // threecamera.position.z += 0.01;
    }
    window.requestAnimationFrame(rerender2);
}
window.requestAnimationFrame(rerender2);

async function renderChildren(ctx: CanvasRenderingContext2D, children: any[], last: any) {
    if (!children) return;
    for (let ch of children) {
        const newProps: any = {};
        const obj = objectTypes[ch.type];
        if (!obj) {
            console.warn(`Object type ${ch.type} not registered. Ensure you added it to "renderer/objects/index" file.`);
            continue;
        }

        const childLast: any = {};
        for (const prop in ch) {
            newProps[prop] = ch[prop]
            if (propTypes[prop]) {
                if (propTypes[prop].isArray) {
                    newProps[prop] = ch[prop].map((eq: any, i: number) => {
                        if (eq && eq === 'inherit') {
                            return last[prop][i];
                        } else if (eq && eq[0] === '=' && !propTypes[prop].customCalc) {
                            try {
                                return math.eval(eq.slice(1), {frame: store.frame, time: store.frame/30, unixtime: Date.now()/1000});
                            } catch { return ''; }
                        }
                        return eq;
                    });
                } else {
                    if (ch[prop] && ch[prop] === 'inherit') {
                        newProps[prop] = last[prop];
                    } else if (ch[prop] && ch[prop][0] === '=' && !propTypes[prop].customCalc) {
                        try {
                            newProps[prop] = math.eval(ch[prop].slice(1), {
                                frame: store.frame, time: store.frame/30, unixtime: Date.now()/1000,
                                rgb: (r: number,g: number,b: number) => `rgb(${r}, ${g}, ${b})`,
                                hsv: HSVtoRGB,
                            });
                        } catch {}
                    }
                }
                childLast[prop] = ch[prop];
            }
        }

        ctx.save();
        ctx.globalCompositeOperation = newProps.composite;
        newProps.translate && ctx.translate(newProps.translate[0], newProps.translate[1]);
        newProps.rotate && ctx.rotate((newProps.rotate/180)*Math.PI);
        newProps.scale && ctx.scale(newProps.scale[0], newProps.scale[1]);
        
        obj.render(ctx, newProps);

        for (const k in newProps) {
            if (k[0] === '_' && newProps[k] !== ch[k]) {
                ch[k] = newProps[k];
            }
        }

        renderChildren(ctx, ch.children, childLast);

        ctx.restore();
    }
}

function HSVtoRGB(h: number, s: number, v: number) {
    var r, g, b, i, f, p, q, t;
    i = Math.floor(h * 6);
    f = h * 6 - i;
    p = v * (1 - s);
    q = v * (1 - f * s);
    t = v * (1 - (1 - f) * s);
    switch (i % 6) {
        case 0: r = v, g = t, b = p; break;
        case 1: r = q, g = v, b = p; break;
        case 2: r = p, g = v, b = t; break;
        case 3: r = p, g = q, b = v; break;
        case 4: r = t, g = p, b = v; break;
        case 5: r = v, g = p, b = q; break;
    }
    return `rgb(${Math.round(r * 255)}, ${Math.round(g * 255)}, ${Math.round(b * 255)})`;
}

export { rerender, renderChildren };
