import * as React from "react";
import styled from "styled-components";
import { theme } from "../../theme";

/**
 * Simple Menu Item
 */
class Item extends React.Component<{
    data: any,
    title: string,
    icon: string,
    selected?: boolean,
    onClick?: any,
    fontSize?: string,
}> {
    render() {
        const { data, title, icon, selected, onClick, fontSize } = this.props;
        const H = fontSize ? HolderStatic : Holder;
        return <H
            onClick={(e) => {
                onClick(e, data);
            }}
            style={{
                background: selected ? theme.highlighted : 'white', overflow: 'hidden', minWidth: '35px',
            }}
        >
            <i className="material-icons">{icon}</i>
            <div>{title}</div>
        </H>;
    }
}

const Holder = styled.div`
    margin: 1px;
    line-height: 24px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    user-select: none;
    min-height: 24px;

    @media only screen and (max-width: ${(e) => e.theme.smallWidth}px) {
        flex-direction: column;
        align-items: center; text-align: center;
        font-size: 6px; line-height: 10px;
        padding: 5px;
    }
`;

const HolderStatic = styled.div`
    margin: 1px;
    line-height: 24px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    user-select: none;
    min-height: 24px;
`;

export { Item };
