import { SceneObject, uniqueId, baseProps } from "./object";
import * as THREE from 'three';
import { threescene } from "../three";

const buildprop = ['primitive', 'material', 'radialSegments'];

export const geometry: SceneObject = {
    create: (props: any) => {
        return {
            id: 'Geometry ' + uniqueId(),
            type: 'geometry',
            primitive: 'box',
            material: 'basic',
            translate: [0, 0, 0],
            rx: 0,
            ry: 0,
            rz: 0,
            scale: [30, 30, 30],
            radialSegments: 8
        };
    },
    destroy: (props) => {
        if (props._mesh) {
            threescene.remove(props._mesh);
        }
    },
    render: async (ctx, props) => {
        let same = true;
        if (props._last) {
            for (var e of buildprop) {
                if (props[e] !== props._last[e]) {
                    same = false;
                    break;
                }
            }
        }
        if (!props._mesh || !same) {
            if (props._mesh) {
                threescene.remove(props._mesh);
                console.log('rebuilding mesh');
            }

            var geometry = null;
            if (props.primitive === 'box') geometry = new THREE.BoxGeometry( 1, 1, 1 );
            else if (props.primitive === 'cone') geometry = new THREE.ConeGeometry( 1, 2, props.radialSegments );
            else if (props.primitive === 'plane') {
                geometry = new THREE.PlaneGeometry( 50, 50, 50, 50 );
            }
            var material = null;
            if (props.material === 'wireframe') {
                material = new THREE.MeshBasicMaterial({color: 0x888888, side: THREE.DoubleSide, wireframe: true});
            } else {
                material = new THREE.MeshStandardMaterial({ side: THREE.DoubleSide }); // new THREE.MeshNormalMaterial();
            }

            var mesh = new THREE.Mesh( geometry, material );
            threescene.add( mesh );
            props._mesh = mesh;
            props._last = {...props};
        }
        props._mesh.position.x = props.translate[0];
        props._mesh.position.y = props.translate[1];
        props._mesh.position.z = props.translate[2];
        props._mesh.rotation.x = props.rx + (props.primitive === 'plane' ? Math.PI/2 : 0);
        props._mesh.rotation.y = props.ry;
        props._mesh.rotation.z = props.rz;
        props._mesh.scale.x = props.scale[0];
        props._mesh.scale.y = props.scale[1];
        props._mesh.scale.z = props.scale[2];
    }
};
