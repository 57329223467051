import { SceneObject, uniqueId, baseProps } from "./object";
import { store } from "../../store";
import { rerender } from "../renderer";

const file: SceneObject = {
    create: (props: any) => {
        return {
            id: 'File ' + uniqueId(),
            type: 'file',
            ...baseProps(),
            anchor: [0,0],
            data: '',
            ...props
        };
    },
    render: async (ctx, data) => {
        if (!data._img || !data._img.width) {
            data._img = new Image();
            data._img.src = data.data;
            data._img.onload = () => {
                rerender();
            }
        }
        ctx.drawImage(
            data._img,
            -data._img.width*data.anchor[0],
            -data._img.height*data.anchor[1]
        );
    }
};

export { file };
