import { qr } from "./qr";
import { rect } from "./rect";
import { SceneObject } from "./object";
import { file } from "./file";
import { line } from "./line";
import { kernel } from "./kernel";
import { levels } from "./levels";
import { wave } from "./wave";
import { geometry } from "./geometry";
import { pixelart } from "./pixelart";

const objectTypes: { [key: string]: SceneObject } = {
    line: line,
    qr: qr,
    rect: rect,
    file: file,
    pixelart: pixelart,
    kernel: kernel,
    wave: wave,
    levels: levels,
    geometry: geometry,
}

export { objectTypes };
