
interface SceneObject {
    create: (props?: any) => any,
    destroy?: (props: any) => void,
    render: (ctx: CanvasRenderingContext2D, data: any, good?: boolean) => void,
    controls?: (props: any) => any,
}

const uniqueId = () => {
    return Math.floor(Math.random()*1000000000).toString(16);
}

const baseProps = () => {
    return {
        translate: [0, 0],
        rotate: 0,
        scale: [1, 1],
        composite: "source-over",
        children: [] as any,
    }
}

const propTypes: { [key: string]: any } = {
    translate: {type: 'number', isArray: true},
    rotate: {type: 'number'},
    scale: {type: 'number', isArray: true},
    x: {type: 'number'}, y: {type: 'number'}, z: {type: 'number'},
    rx: {type: 'number'}, ry: {type: 'number'}, rz: {type: 'number'},
    width: {type: 'number'}, length: {type: 'number'}, height: {type: 'number'},
    color: {type: 'string'},
    fillColor: {type: 'string'},
    anchor: {type: 'number', isArray: true},
    radius: {type: 'number'}, // Used in kernel filters and rects
    strokeWidth: {type: 'number'},
    start: {type: 'number'},
    end: {type: 'number'},
    alpha: {type: 'boolean', uncache: true}, // Used in kernel filters
    quality: {type: 'number', uncache: true}, // Used in kernel filters
    content: {type: 'string', uncache: true}, // Used in QR codes
    low: {type: 'number'},
    high: {type: 'number'},
    style: {type: 'string', options: ['simple', 'variable', 'inherit']}, // line styles
    smoothing: {type: 'boolean'},
    composite: {type: 'string', options: ["source-over", "source-in", "source-out", "source-atop", "destination-over", "destination-in", "destination-out", "destination-atop", "lighter", "copy", "xor", "multiply", "screen", "overlay", "darken", "color-dodge", "color-burn", "hard-light", "soft-light", "difference", "exclusion", "hue", "saturation", "color", "luminosity"]},
    frequency: {type: 'number'},
    primitive: {type: 'string', options: ['box', 'cone', 'plane']},
    material: {type: 'string', options: ['basic', 'wireframe']},
    radialSegments: {type: 'number'},
    // Wave Filter
    amplitude: {type: 'number'},
    offset: {type: 'number'},
    angle: {type: 'number'},
    // Pixel Art Tools
    // palette: {type: 'string', isArray: true},
    frame: {type: 'number'},
    spritesheet: {type: 'boolean'},
    red: {type: 'string', customCalc: true},
    green: {type: 'string', customCalc: true},
    blue: {type: 'string', customCalc: true},
    // Tool settings
    paletteColor: {type: 'number'},
    backgroundColor: {type: 'string'},
    size: {type: 'string'},
};

export { SceneObject, uniqueId, baseProps, propTypes };
