import { SceneObject, uniqueId, baseProps } from "./object";
import { vec2 } from "gl-matrix";

/**
 * This is a poly line renderer which can be added to any scene.
*/
export const line: SceneObject = {
    create: (props: any) => {
        return {
            id: 'Line ' + uniqueId(),
            type: 'line',
            ...baseProps(),
            strokeWidth: 'inherit',
            color: 'inherit',
            fillColor: 'inherit',
            start: 0,
            end: 1,
            points: [],
            style: 'inherit',
            smoothing: true,
            ...props
        };
    },
    render: async (ctx, data) => {
        if (!data.points[0]) return;

        var lineLength = 0;
        for (let i=1; i<data.points.length; i++) {
            lineLength += vec2.distance(data.points[i-1], data.points[i]);
        }

        if (data.style === 'variable') {
            variable(ctx, data, lineLength);
        } else {
            simple(ctx, data, lineLength);
        }
    }
};

// + Math.sin((x/30)+(Date.now()/500))*30 (add to x or y points for wavy effect)
function simple(ctx: CanvasRenderingContext2D, { points, strokeWidth, color, fillColor }: {[key:string]:any}, lineLength: number) {
    ctx.beginPath();
    points.forEach(([x, y]: number[], i: number) => {
        if (i === 0) ctx.moveTo(x, y);
        else ctx.lineTo(x, y);
    });
    //ctx.closePath();
    ctx.lineWidth = strokeWidth;
    ctx.strokeStyle = color;
    ctx.fillStyle = fillColor;
    ctx.lineCap = "round";
    ctx.lineJoin = "round";
    ctx.fill();
    if (Number(strokeWidth) > 0) ctx.stroke();
}

function variable(ctx: CanvasRenderingContext2D, { points, strokeWidth, color, fillColor, smoothing, start, end }: {[key:string]:any}, lineLength: number) {
    const pnts: any = [];
    ctx.beginPath();
    ctx.moveTo(points[0][0], points[0][1]);
    for (var i=1; i<points.length-1; i++) {
        ctx.lineTo(points[i][0], points[i][1]);

        const startPoint = (start*points.length);
        const endPoint = ((1-end)*points.length);
        let distFromEnd = strokeWidth * Math.max(Math.min(i-startPoint, points.length-i-endPoint, 15),0)/60;
        const a = points[i-1];
        const b = points[i+1];
        const c = points[i];

        let tangentA = vec2.create();
        vec2.sub(tangentA, c, a)
        vec2.normalize(tangentA, tangentA);
        tangentA = vec2.fromValues(-tangentA[1], tangentA[0]);
        vec2.scale(tangentA, tangentA, distFromEnd);

        let tangentB = vec2.create();
        vec2.sub(tangentB, b, c)
        vec2.normalize(tangentB, tangentB);
        tangentB = vec2.fromValues(-tangentB[1], tangentB[0]);
        vec2.scale(tangentB, tangentB, distFromEnd);

        let oppA = vec2.clone(tangentA);
        vec2.scale(oppA, oppA, -1);

        let oppB = vec2.clone(tangentB);
        vec2.scale(oppB, oppB, -1);

        vec2.add(tangentA, tangentA, c);
        vec2.add(tangentB, tangentB, c);
        vec2.add(oppA, oppA, c);
        vec2.add(oppB, oppB, c);

        pnts.push(tangentA);
        pnts.push(tangentB);
        pnts.unshift(oppA);
        pnts.unshift(oppB);
    }

    ctx.fillStyle = fillColor;
    ctx.fill();

    ctx.beginPath();

    if (smoothing) {
        if (i === 0) ctx.moveTo(pnts[0][0], pnts[0][0]);
        for (var i=1; i<pnts.length-1; i++) {
            const [x, y] = pnts[i];
            const [u, w] = pnts[i+1];
            ctx.quadraticCurveTo(x, y, u, w);
        }
    } else {
        pnts.forEach(([x, y]: number[], i: number) => {
            if (i === 0) ctx.moveTo(x, y);
            else ctx.lineTo(x, y);
        });
    }
    //ctx.closePath();
    ctx.lineWidth = 2;
    ctx.fillStyle = color;
    ctx.lineCap = "round";
    ctx.lineJoin = "round";
    ctx.fill();
    //ctx.strokeStyle = 'red';
    //ctx.stroke();

    /*points.forEach(([x, y]: number[], i: number) => {
        let distFromEnd = strokeWidth * Math.min(i, points.length-i, 30)/60;
        ctx.beginPath();
        ctx.arc(x, y, distFromEnd, 0, 2 * Math.PI);
        ctx.fill();
    });*/
}

//export { line };
