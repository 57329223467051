import { SceneObject, uniqueId, baseProps } from "./object";

const vertSrc = `
    attribute vec2 a_position;
    attribute vec2 a_texCoord;
    uniform vec2 u_resolution;
    varying vec2 v_texCoord;

    void main() {
        vec2 clipSpace = (2.0*(a_position/u_resolution))-1.0;
        gl_Position = vec4(clipSpace * vec2(1, -1), 0, 1);
        v_texCoord = a_texCoord;
    }
`;

const fragSrc = `
    precision mediump float;

    uniform sampler2D u_image;
    uniform float u_frequency;
    uniform float u_amplitude;
    uniform float u_offset;
    uniform float u_angle;
    varying vec2 v_texCoord;

    void main() {
        // Get position along wave
        float pos = v_texCoord[0] * cos(u_angle) + v_texCoord[1] * sin(u_angle);
        // Get offset based on position along wave (540.0 is canvas width)
        float px = sin(u_offset+pos*u_frequency) * u_amplitude;
        
        // Figure out how to mix horizontal pixels
        float xOff = sin(u_angle) * px * 960.0;
        float xLeft = floor(xOff) / 960.0;
        float xRight = ceil(xOff) / 960.0;
        float xProg = xOff - floor(xOff);
        
        // Figure out how to mix vertical pixels
        float yOff = -cos(u_angle) * px * 540.0;
        float yTop = floor(yOff) / 540.0;
        float yBottom = ceil(yOff) / 540.0;
        float yProg = yOff - floor(yOff);

        gl_FragColor = (
            (
                texture2D(
                    u_image, vec2(
                        v_texCoord.x + xLeft, 
                        v_texCoord.y + yTop
                    )
                )*(1.0-xProg) +
                texture2D(
                    u_image, vec2(
                        v_texCoord.x + xRight, 
                        v_texCoord.y + yTop
                    )
                )*xProg
            )*(1.0-yProg) +
            (
                texture2D(
                    u_image, vec2(
                        v_texCoord.x + xLeft, 
                        v_texCoord.y + yBottom
                    )
                )*(1.0-xProg) +
                texture2D(
                    u_image, vec2(
                        v_texCoord.x + xRight, 
                        v_texCoord.y + yBottom
                    )
                )*xProg
            )*yProg
        );
    }
`;

function setupShader(gl: WebGLRenderingContext) {
	var frag = gl.createShader(gl.FRAGMENT_SHADER);
	gl.shaderSource(frag, fragSrc);
	gl.compileShader(frag); 
	var vert = gl.createShader(gl.VERTEX_SHADER);
	gl.shaderSource(vert, vertSrc);
	gl.compileShader(vert); 
	
	var shaderProgram = gl.createProgram();
	gl.attachShader(shaderProgram, vert);
	gl.attachShader(shaderProgram, frag);
	gl.linkProgram(shaderProgram);
	return shaderProgram;
}

const wave: SceneObject = {
    create: (props: any) => {
        return {
            id: 'Wave ' + uniqueId(),
            type: 'wave', 
            frequency: 10,
            amplitude: 0.2,
            offset: 0,
            radius: 10,
            angle: 0,
            ...props
        };
    },
    render: async (ctx, data) => {
        // Setup reusable gl canvas and shader
        const { width, height } = ctx.canvas;
        let canvas: HTMLCanvasElement, gl: WebGLRenderingContext, shader: WebGLProgram;
        if (!data._gl || !data._gl.useProgram) {
            canvas = data._canvas = document.getElementById('glcanvas') as HTMLCanvasElement;
            gl = data._gl = canvas.getContext('webgl');
            shader = data._shader = setupShader(gl);
        } else {
            canvas = data._canvas;
            gl = data._gl;
            shader = data._shader;
        }
        gl.useProgram(shader);
        // Convert canvas content to gl texture
        var texture = gl.createTexture();
        gl.bindTexture(gl.TEXTURE_2D, texture);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
        // if (data.kernel === 'sharpen') 
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, ctx.canvas);
        // else gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, canvas);
        // Generate mesh to assign texture
        var texCoordBuffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, texCoordBuffer);
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([
            0,0,
            1,0,
            0,1,
            0,1,
            1,0,
            1,1
        ]), gl.STATIC_DRAW);
        var texCoordLocation = gl.getAttribLocation(shader, "a_texCoord");
        gl.enableVertexAttribArray(texCoordLocation);
        gl.vertexAttribPointer(texCoordLocation, 2, gl.FLOAT, false, 0, 0);
        // Pass data to vertex shader.
        var resolutionLocation = gl.getUniformLocation(shader, "u_resolution");
        gl.uniform2f(resolutionLocation, width, height);
        // Pass data to fragment shader.
        // Pass frequency and amplitude
        var kernelLocation = gl.getUniformLocation(shader, "u_frequency");
        gl.uniform1f(kernelLocation, data.frequency);
        kernelLocation = gl.getUniformLocation(shader, "u_amplitude");
        gl.uniform1f(kernelLocation, data.amplitude);
        kernelLocation = gl.getUniformLocation(shader, "u_offset");
        gl.uniform1f(kernelLocation, data.offset);
        kernelLocation = gl.getUniformLocation(shader, "u_angle");
        gl.uniform1f(kernelLocation, data.angle);
        // Draw triangles.
        var positionBuffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
        var positionLocation = gl.getAttribLocation(shader, "a_position");
        gl.enableVertexAttribArray(positionLocation);
        gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 0, 0);
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([
            0, 0,
            width, 0,
            0, height,
            0, height,
            width, 0,
            width, height
        ]), gl.STATIC_DRAW);
        gl.drawArrays(gl.TRIANGLES, 0, 6);
        ctx.clearRect(0, 0, width, height);
        ctx.drawImage(canvas, 0, 0, width, height);
    }
};

export { wave };
