import * as React from "react";
import styled from "styled-components";
import { theme } from "../../theme";
import { store, scene } from "../../store";
import { observer } from "mobx-react";

export const Timeline = observer(() => {
    
    const frames = new Array(240).fill(null);
    return <Holder>
        <div>
            <Button onClick={() => {
                store.playing = !store.playing;
                store.playingStart = Date.now();
            }}>Play</Button>
        </div>
        <Scroll>
            {frames.map((d, i) => {
                return <TimelineCell key={i} cell={i} />
            })}
        </Scroll>
    </Holder>
});

export const TimelineCell = observer(({cell}) => {
    const frameData = store.frameStates.length > cell && store.frameStates[cell];
    return <FrameIcon selected={frameData && frameData.active} onMouseEnter={() => {
        if (!store.rendering) {
            store.frame = cell;
        }
    }}><FrameText>{cell}</FrameText></FrameIcon>;
});

const Holder = styled.div`
    position: relative;
    background: ${theme.paneColor};
    display: flex; flex-direction: column;
`;
const Scroll = styled.div`
    position: relative;
    overflow: auto; white-space: nowrap;
`;
const FrameIcon = styled.div<{selected?: boolean}>`
    position: relative;
    background: ${({selected}) => selected ? theme.highlighted : 'white'};
    width: 15px; height: 30px;
    margin: 1px; display: inline-block;
    cursor: pointer;
    user-select: none;
`;
const FrameText = styled.div`
    position: absolute; left: 105%; bottom: 2px;
    font-size: 13px;
    transform-origin: 0% 100%;
    transform: rotate(-90deg);
`;
export const Button = styled.div`
    display: inline-block;
    background: white;
    cursor: pointer;
    user-select: none;
    margin: 5px; padding: 5px;
`;