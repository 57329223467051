import { Tool } from "./tool";
import { store, state, scene } from "../store";
import { qr } from "../renderer/objects/qr";
import { line } from "../renderer/objects/line";
import { rerender } from "../renderer/renderer";

const SettingsTool: Tool = {
    name: 'Scene Settings',
    icon: 'settings',
    shortcut: 'x',
    settings: {
        set width(val) {
            scene.dimensions[0] = val;
        },
        get width() {
            return scene.dimensions[0];
        },
        set height(val) {
            scene.dimensions[1] = val;
        },
        get height() {
            return scene.dimensions[1];
        },
        set backgroundColor(val) {
            scene.backgroundColor = val;
        },
        get backgroundColor() {
            return scene.backgroundColor;
        },
    },
    onSelect: () => {
        store.toolsettings = true;
        return true;
    }
};

export { SettingsTool };
