import { Tool } from "./tool";
import { store, state, scene } from "../store";
import { qr } from "../renderer/objects/qr";
import { line } from "../renderer/objects/line";
import { rerender } from "../renderer/renderer";
import { vec2 } from "gl-matrix";

let closest = -1;
let near: {i: number, s: number}[] = [];

const PointsTool: Tool = {
    name: 'Poin(t)s',
    icon: 'scatter_plot',
    shortcut: 't',
    settings: {
        size: 100,
    },
    onSelect: () => {
        store.toolsettings = true;
        store.showCursor = true;
        return true;
    },
    onMouseDown: () => {
        const [x, y] = store.canvasPosition;
        const v = vec2.fromValues(x-state.object.translate[0], y-state.object.translate[1]);
        vec2.rotate(v, v, vec2.create(), -Math.PI*(state.object.rotate/180));
         
        closest = -1;
        near = [];
        let closestDistance = 400;
        state.object.points.forEach((p: number[], i: number) => {
            const d = Math.pow(v[0]-p[0], 2) + Math.pow(v[1]-p[1], 2);
            if (d < closestDistance) {
                closest = i;
                closestDistance = d;
            }
            const maxDist = Math.pow(PointsTool.settings.size/2, 2);
            if (d < maxDist) {
                near.push({i, s: (Math.cos(Math.PI*d/maxDist)+1)/2});
            }
        });
        rerender();
    },
    onMouseMove: ([dx, dy]: number[]) => {
        for (const e of near) {
            const v = vec2.fromValues(dx, dy);
            vec2.rotate(v, v, vec2.create(), -Math.PI*(state.object.rotate/180));
            state.object.points[e.i][0] += v[0] * e.s;
            state.object.points[e.i][1] += v[1] * e.s;
        }
    },
    onMouseUp: () => {
        near = []
        closest = -1;
    }
};

export { PointsTool };
