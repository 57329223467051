import * as React from "react";
import { styled, theme } from "../../theme";
import { qr } from "../../renderer/objects/qr";
import { observer } from "mobx-react";
import { store, scene, state } from "../../store";
import { objectTypes } from "../../renderer/objects";
import { initThree, threecamera } from "../../renderer/three";

const canvases: any = {};

@observer
class WorkArea extends React.Component {
    touchStart(e: any) {
        if (e.button === 0 || !e.button) {
            if (e.touches && e.touches[0]) {
                e = e.touches[0];
            }
            store.mousePosition = [e.clientX, e.clientY];
            store.dragging = true;
            state.tool && state.tool.onMouseDown && state.tool.onMouseDown(e);
        } else {
            store.rightdragging = true;
        }
    }
    wheel(e: React.WheelEvent<HTMLCanvasElement>) {
        threecamera.position.z = Math.max(1, Math.min(2000, threecamera.position.z + e.deltaY));
    }
    componentDidMount() {
        initThree();
        canvases['2d'].onTouchMove = (e) => e.preventDefault();
    }
    render() {
        return <Area>
            <div style={{ position: "relative", margin: "auto", padding: "10px" }}>
                <div id="threeholder" style={{ position: "absolute", top: "10px", left: "10px" }}></div>
                <Canvas id="maincanvas" style={{
                    transform: `Rotate(${store.canvasRotation}deg)`
                }} ref={(c)=>canvases['2d']=c} width="960" height="540"
                    onMouseDown={this.touchStart}
                    onTouchStart={this.touchStart}
                    onWheel={this.wheel}
                />
                <Canvas id="glcanvas" style={{ display: 'none' }} ref={(c)=>canvases['gl']=c} width="960" height="540" />
            </div>
        </Area>;
    }
}

const Area = styled.div`
    flex: 1;
    background: ${theme.bgColor};
    overflow: auto;
    display: flex;
    box-sizing: border-box;
`;

const Canvas = styled.canvas`
    /* background: white; */
    width: 960px;
    height: 540px;
`;

export { WorkArea, canvases };
