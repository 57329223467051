import { Tool } from "./tool";
import { scene, store, state } from "../store";
import { kernel } from "../renderer/objects/kernel";
import { rerender } from "../renderer/renderer";

const OutlineTool: Tool = {
    name: 'O(u)tline Filter',
    icon: 'blur_on',
    shortcut: 'u',
    onSelect: () => {
        const obj = kernel.create({ kernel: 'outline' });
        scene.children.push(obj);
        store.object = obj.id;
        state.object = obj;
        rerender();
        return false;
    }
};

export { OutlineTool };
