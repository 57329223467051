import { Tool } from "./tool";
import { scene, store } from "../store";
import { renderChildren } from "../renderer/renderer";
import { canvases } from "../components/organisms/WorkArea";
import { threerenderer, threescene, threecamera } from "../renderer/three";
// import { RayTracingRenderer } from 'ray-tracing-renderer';

declare const WebMWriter: any;

const ExportTool: Tool = {
    name: 'Export Movie',
    icon: 'save',
    shortcut: '__',
    onSelect: () => {
        // const raytrace = true;
        let renderer3d = threerenderer;
        // if (raytrace) {
        //     renderer3d = new RayTracingRenderer(); 
        //     renderer3d.setSize(scene.dimensions[0], scene.dimensions[1]);
        // }

        store.rendering = true;
        store.frame = 0;
        
        const canvas: HTMLCanvasElement = canvases['2d'];
        const ctx = canvas.getContext('2d');

        const videoWriter = new WebMWriter({
            quality: 0.9,
            frameRate: 30,
        });

        async function capFrame() {
            console.log('Saving Frame');

            // Scene default properties
            let last: any = {
                radius: 0,
                strokeWidth: 10,
                color: "rgba(0,150,100,1)",
                fillColor: "rgba(0,0,0,0)",
                style: "variable",
            };
            
            ctx.clearRect(0, 0, scene.dimensions[0], scene.dimensions[1]);
            renderChildren(ctx, scene.children, last);
            renderer3d.render( threescene, threecamera );
            ctx.save();
            ctx.globalCompositeOperation = "destination-over";
            ctx.drawImage(renderer3d.domElement, 0, 0, canvas.width, canvas.height);
            ctx.restore();

            videoWriter.addFrame(canvas);
            if (store.frame < 239) {
                store.frame++;
                setImmediate(capFrame);
            } else {
                const blob = await videoWriter.complete();
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = 'video.webm';
                a.click();
                window.URL.revokeObjectURL(url);
                store.rendering = false;
            }
        }
        capFrame();
        return false;
    }
};

export { ExportTool };
