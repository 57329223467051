import * as React from "react";
import { observer } from "mobx-react";
import { theme } from "../../theme";
import styled from "styled-components";
import { Item } from "../atoms/Item";
import { tools } from "../../tools/index";
import { store } from "../../store";
import { setTool } from "../../actions/setTool";
import { ListArea, InputHolder, Input } from "../atoms/ObjectTree";

@observer
class ToolPanel extends React.Component {
    state = {
        search: ''
    }
    render() {
        const { search } = this.state;
        const mini = store.browserSize[0] < 700;
        const M = mini ? MainTop : Main;
        return <M>
            <InputHolder>
                <Input
                    id="toolsearch"
                    placeholder="(T)ools..."
                    value={search}
                    onChange={(e) => {
                        this.setState({ search: e.currentTarget.value });
                    }}
                    onKeyUp={(e) => {
                        if (e.key === 'Escape') {
                            this.setState({ search: '' });
                            e.currentTarget.blur();
                        } else if (e.key === 'Enter') {
                            if (search) {
                                const firstTool = tools.filter((e) => {
                                    return !search || e.name.toLowerCase().replace(/[()]+/g, '').indexOf(search.toLowerCase()) !== -1;
                                })[0];
                                if (firstTool) {
                                    setTool(firstTool);
                                }
                            }
                            this.setState({ search: '' });
                            e.currentTarget.blur();
                        }  
                    }}
                />
            </InputHolder>
            <ListArea style={{flexDirection: mini ? 'row' : 'column'}}>
                {tools.filter((e) => {
                    return !search || e.name.toLowerCase().replace(/[()]+/g, '').indexOf(search.toLowerCase()) !== -1;
                }).map((e, i) => {
                    return <Item
                        key={i}
                        data={e}
                        title={e.name}
                        icon={e.icon}
                        selected={store.tool===e.name}
                        onClick={(e: any, data: any) => {
                            setTool(data);
                        }}
                    />
                })}
            </ListArea>
        </M>;
    }
}

const Main = styled.div`
    width: 200px; overflow: hidden;
    background: ${theme.paneColor};
    display: flex; flex-direction: column;

    @media only screen and (max-width: ${(e) => e.theme.smallWidth}px) {
        width: 50px;
    }
`;

const MainTop = styled.div`
    height: 50px; overflow: hidden;
    background: ${theme.paneColor};
    display: flex; flex-direction: row;
`;

export { ToolPanel };
