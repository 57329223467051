import { SceneObject, uniqueId, baseProps } from "./object";

const rect: SceneObject = {
    create: (props: any) => {
        return {
            id: 'Rectangle ' + uniqueId(),
            type: 'rect',
            ...baseProps(),
            x: 0,
            y: 0,
            width: 20,
            height: 20,
            radius: 'inherit',
            strokeWidth: 'inherit',
            color: 'inherit',
            fillColor: 'inherit',
            ...props
        };
    },
    render: async (ctx, { x, y, width: w, height: h, radius: r, color, fillColor, strokeWidth }) => {
        if (w < 2 * r) r = w / 2;
        if (h < 2 * r) r = h / 2;
        ctx.beginPath();
        ctx.moveTo(x+r, y);
        ctx.arcTo(x+w, y,   x+w, y+h, r);
        ctx.arcTo(x+w, y+h, x,   y+h, r);
        ctx.arcTo(x,   y+h, x,   y,   r);
        ctx.arcTo(x,   y,   x+w, y,   r);
        ctx.closePath();
        if (fillColor) {
            ctx.fillStyle = fillColor;
            ctx.fill();
        }
        if (color) {
            ctx.strokeStyle = color;
            ctx.lineWidth = strokeWidth;
            if (strokeWidth > 0) ctx.stroke();
        }
        return ctx;
    }
};

export { rect };
