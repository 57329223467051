import { Tool } from "./tool";
import { state, store, scene } from "../store";
import { rerender } from "../renderer/renderer";

const RotateTool: Tool = {
    name: 'Rotate (b)',
    icon: 'fullscreen',
    shortcut: 'b',
    onMouseMove: (delta: number[]) => {
        if (state.object && state.object.translate) {
            if (store.dragging) {
                state.object.rotate = (Number(state.object.rotate) || 0) + delta[0]*scene.scale/20;
                rerender();
            }
        }
    }
};

export { RotateTool };
