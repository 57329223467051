
const vertSrc = `
attribute vec2 a_position;
attribute vec2 a_texCoord;
uniform vec2 u_resolution;
varying vec2 v_texCoord;

void main() {
    vec2 clipSpace = (2.0*(a_position/u_resolution))-1.0;
    gl_Position = vec4(clipSpace * vec2(1, -1), 0, 1);
    v_texCoord = a_texCoord;
}
`;

function glInit(ctx: CanvasRenderingContext2D, data: any, setupShader: any) {
    // Setup reusable gl canvas and shader
    const { width, height } = ctx.canvas;
    let canvas: HTMLCanvasElement, gl: WebGLRenderingContext, shader: WebGLProgram;
    if (!data._gl || !data._gl.useProgram) {
        canvas = data._canvas = document.getElementById('glcanvas') as HTMLCanvasElement;
        gl = data._gl = canvas.getContext('webgl');
        shader = data._shader = shaderInit(gl, data, setupShader);
    } else {
        canvas = data._canvas;
        gl = data._gl;
        shader = data._shader;
    }
    gl.useProgram(shader);
    // Convert canvas content to gl texture
    var texture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
    // if (data.kernel === 'sharpen') 
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, ctx.canvas);
    // else gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, canvas);
    // Generate mesh to assign texture
    var texCoordBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, texCoordBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([
        0,0,
        1,0,
        0,1,
        0,1,
        1,0,
        1,1
    ]), gl.STATIC_DRAW);
    var texCoordLocation = gl.getAttribLocation(shader, "a_texCoord");
    gl.enableVertexAttribArray(texCoordLocation);
    gl.vertexAttribPointer(texCoordLocation, 2, gl.FLOAT, false, 0, 0);
    // Pass data to vertex shader.
    var resolutionLocation = gl.getUniformLocation(shader, "u_resolution");
    gl.uniform2f(resolutionLocation, width, height);
    return [gl, shader];
}

function shaderInit(gl: any, data: any, setupShader: any) {
    const fragSrc = setupShader(data);

    var frag = gl.createShader(gl.FRAGMENT_SHADER);
	gl.shaderSource(frag, fragSrc);
	gl.compileShader(frag); 
	var vert = gl.createShader(gl.VERTEX_SHADER);
	gl.shaderSource(vert, vertSrc);
	gl.compileShader(vert); 
	console.log(fragSrc);
	var shaderProgram = gl.createProgram();
	gl.attachShader(shaderProgram, vert);
	gl.attachShader(shaderProgram, frag);
    gl.linkProgram(shaderProgram);
    
    return shaderProgram;
}

function glDraw(gl: WebGLRenderingContext, ctx: CanvasRenderingContext2D, shader: WebGLProgram, width: number, height: number) {
    // Draw triangles.
    var positionBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
    var positionLocation = gl.getAttribLocation(shader, "a_position");
    gl.enableVertexAttribArray(positionLocation);
    gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 0, 0);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([
        0, 0,
        width, 0,
        0, height,
        0, height,
        width, 0,
        width, height
    ]), gl.STATIC_DRAW);
    gl.drawArrays(gl.TRIANGLES, 0, 6);
    ctx.clearRect(0, 0, width, height);
    ctx.drawImage(gl.canvas, 0, 0, width, height);
}

export { vertSrc, glInit, glDraw };
