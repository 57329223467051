import { Tool } from "./tool";
import { scene } from "../store";

function download(filename: string, text: string) {
    var blob = new Blob([text], {type: 'application/text'});
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
    } else{
        var e = document.createEvent('MouseEvents'),
        a = document.createElement('a');
        a.download = filename;
        a.href = window.URL.createObjectURL(blob);
        a.dataset.downloadurl = ['application/text', a.download, a.href].join(':');
        a.click();
    }
}

const SaveSceneTool: Tool = {
    name: 'Save Scene (a)',
    icon: 'save',
    shortcut: 'a',
    onSelect: () => {
        for (var obj of scene.children) {
            for (var prop in obj) {
                if (prop[0] === '_') obj[prop] = undefined;
            }
        }
        download('scene.json', JSON.stringify(scene,null,2));
        return false;
    }
};

export { SaveSceneTool };
