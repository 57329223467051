import { configure } from "mobx";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Editor } from "./components/templates/Editor";

configure({
    enforceActions: "never",
});

ReactDOM.render(
    <Editor />,
    document.getElementById("root"),
);
