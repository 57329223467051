import { NodeEditor } from "flume";
import { observer } from "mobx-react";
import * as React from "react";
import config, { gamelogic, runFlume } from "../../flume";
import { scene } from "../../store";
import { styled } from "../../theme";
import { Item } from "../atoms/Item";

type Tree = { [key: string]: {
    id: string,
    x: number,
    y: number,
    type: string,
    width: number,
    height?: number,
    connections: {
        inputs: any,
        outputs: any,
    },
    inputData: any,
    root?: boolean,
} };

const GameLogicScreen = observer(() => {
    const [key, setKey] = React.useState("nodeeditor");
    const nodeEditor = React.useRef();

    return <H>
        <FlumeHolder>
            <NodeEditor key={key} ref={nodeEditor}
                portTypes={config.portTypes} nodeTypes={config.nodeTypes}
                circularBehavior='allow'
                defaultNodes={[{type: "start", x: 0, y: 0}]}
                onChange={(newNodes) => {
                    // console.log(JSON.stringify(newNodes));
                    scene.gamelogic = newNodes;
                }} // JSON.parse(JSON.stringify(scene.gamelogic))
                nodes={scene.gamelogic}
            />
        </FlumeHolder>
        <div style={{width: '30%'}}>
            <Item icon={'play_circle'} title={'Run Graph'} data={{}} onClick={() => {
                runFlume();
            }} />
            {gamelogic.messages.map((msg) => {
                return <div style={{margin: '10px'}}>
                    <div style={{fontSize: '8px'}}>{msg.character}</div>
                    <div style={{background: '#316eca', color: 'white', borderRadius: '10px', padding: '10px'}}>{msg.message}</div>
                </div>
            })}
        </div>
    </H>;
});

const FlumeHolder = styled.div`
    width: 70%; height: 100%;
    .Node_label__3MmhF {
        background: #316eca
        color: white;
    }
    .Node_wrapper__3SmT7, .Select_selectedWrapper__SUs4D, .TextInput_input__1QHwS {
        background: white;
        :focus {
            background: white;
        }
    }
`;

const H = styled.div`
    position: relative;
    display: flex; flex: 1;
    flex-direction: row;
    overflow: hidden;
`;

export { GameLogicScreen };
