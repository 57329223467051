import { Tool } from "./tool";
import { scene, store } from "../store";
import { file } from "../renderer/objects/file";
import { rerender } from "../renderer/renderer";

const LoadSceneTool: Tool = {
    name: '(L)oad Scene',
    icon: 'folder',
    shortcut: 'l',
    onSelect: () => {
        const fi = document.createElement('input');
        document.body.appendChild(fi);
        fi.setAttribute('type', 'file');
        fi.addEventListener('change', () => {
            var reader = new FileReader();
            reader.addEventListener("load", function () {
                const scn: any = JSON.parse(reader.result as string);
                for (var s in scn) {
                    (scene as any)[s] = scn[s];
                }
                document.body.removeChild(fi);
                rerender();
            }, false);
            if (fi.files[0]) {
                reader.readAsText(fi.files[0]);
            }
        });
        fi.click();
        return false;
    }
};

export { LoadSceneTool };
