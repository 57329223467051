import { Tool } from "./tool";
import { scene, store, state } from "../store";
import { qr } from "../renderer/objects/qr";
import { rerender } from "../renderer/renderer";

const QRTool: Tool = {
    name: '(Q)R Code',
    icon: 'qr_code',
    shortcut: 'q',
    onSelect: () => {
        const obj = qr.create({});
        scene.children.push(obj);
        store.object = obj.id;
        state.object = obj;
        rerender();
    }
};

export { QRTool };
