import { Tool } from "./tool";
import { store, state, scene } from "../store";
import { qr } from "../renderer/objects/qr";
import { line } from "../renderer/objects/line";
import { rerender } from "../renderer/renderer";

function updatePixel() {
    let [x, y] = store.canvasPosition;
    x = Math.floor(x / 20);
    y = Math.floor(y / 20);
    if (state.object.pixels[state.object.frame][y][x] !== undefined) {
        state.object.pixels[state.object.frame][y][x] = Number(DrawPixelsTool.settings.color);
    }
}

const DrawPixelsTool: Tool = {
    name: 'Draw Pi(x)els',
    icon: 'create',
    shortcut: 'x',
    settings: {
        color: 3,
    },
    onSelect: () => {
        store.toolsettings = true;
        return true;
    },
    onMouseDown: () => {
        updatePixel();
    },
    onMouseMove: ([dx, dy]: number[]) => {
        if (store.dragging) {
            updatePixel();
        }
    },
    onMouseUp: () => {
        if (store.dragging) {}
    }
};

export { DrawPixelsTool };
