import * as THREE from 'three';
// import { VRButton } from 'three/examples/jsm/webxr/VRButton.js';
// import { RayTracingRenderer, RayTracingMaterial } from 'ray-tracing-renderer';

export let threepivot: THREE.Group;
export let threecamera: THREE.PerspectiveCamera;
export let threescene: THREE.Scene;
export let threerenderer: THREE.Renderer;

export function initThree() {
    threepivot = new THREE.Group();
    threepivot.position.set( 0.0, 0.0, 0 );
    threepivot.rotateX(-Math.PI/4);

    threecamera = new THREE.PerspectiveCamera( 70, 960/540, 0.01, 5000 );
    threecamera.position.z = 500;
    // threepivot = threecamera as any; // raytracer doesn't like camera having a parent
    threepivot.add(threecamera);
 
    threescene = new THREE.Scene();
    threescene.background = new THREE.Color( 0xcccccc );
    threescene.add(threepivot); // remove for raytracer
 
    var directionalLight = new THREE.DirectionalLight( 0xffffff, 0.5 );
    directionalLight.position.set(5, 10, 2);
    threescene.add( directionalLight );

    // Raytracer will fail unless objects are added immediately
    // const material3 = new THREE.MeshStandardMaterial();
    // const geometry3 = new THREE.BoxBufferGeometry(100, 100, 100);
    // const mesh3 = new THREE.Mesh(geometry3, material3);
    // mesh3.position.x = 120;
    // threescene.add(mesh3);
    // const material2 = new RayTracingMaterial();
    // material2.transparent = true;
    // material2.solid = true;
    // const geometry2 = new THREE.ConeBufferGeometry(50, 100);
    // const mesh2 = new THREE.Mesh(geometry2, material2);
    // mesh2.position.x = 120;
    // mesh2.position.z += 120;
    // threescene.add(mesh2);

    threerenderer = new THREE.WebGLRenderer( { antialias: true } );
    // (threerenderer as any).xr.enabled = true;
    // (threerenderer as any).vr.enabled = true;
    threerenderer.setSize( 960, 540 );
    document.getElementById('threeholder').appendChild( threerenderer.domElement );
    
    // document.getElementById('threeholder').appendChild( VRButton.createButton(threerenderer as any) );
    // (threerenderer as any).setAnimationLoop(() => {
    //     threerenderer.render(threescene, threecamera);
    // });
}
