import { FlumeConfig, Colors, Controls } from 'flume';
import { makeObservable, observable } from 'mobx';
import { scene } from './store';

const portCaller = (connections) => {
    const pts = 'ABCDEFGHIJK';
    const outp = [];
    let found = false;
    for (let i=pts.length-1; i>0; i--) {
        if (connections.inputs[`call${pts[i-1]}`] || found) {
            outp.unshift({type: 'then', name: `call${pts[i]}`, label: `Do`});
            found = true;
        }
    }
    outp.unshift({type: 'then', name: 'callA', label: 'Do'});
    return outp;
};

const config = new FlumeConfig();
config
    .addPortType({
        type: "character",
        name: "character",
        label: "Character",
        color: Colors.orange,
        controls: [
        Controls.select({
            name: "character",
            label: "Character",
            defaultValue: "inherit",
            options: [
                {value: "inherit", label: "[Inherit]"},
                {value: "billy", label: "Billy"},
                {value: "baily", label: "Baily"},
            ]
        })
        ]
    })
    .addPortType({
        type: "givetake",
        name: "givetake",
        label: "Operation",
        color: Colors.pink,
        controls: [
        Controls.select({
            name: "givetake",
            label: "Operation",
            defaultValue: "give",
            options: [
                {value: "give", label: "Give"},
                {value: "take", label: "Take"},
                {value: "set", label: "Set"},
                {value: "has", label: "Has"},
            ]
        })
        ]
    })
    .addPortType({
        type: "item",
        name: "item",
        label: "Item",
        color: Colors.magenta,
        hidePort: true,
        controls: [
        Controls.select({
            name: "item",
            label: "Item",
            defaultValue: "money",
            options: [
                {value: "money", label: "Money"},
                {value: "userselect", label: "[User Selectable]"},
                {value: "apple", label: "Apple"},
                {value: "orange", label: "Orange"},
                {value: "sneakers", label: "Sneakers"},
            ]
        })
        ]
    })
    .addPortType({
        type: "count",
        name: "count",
        label: "Count",
        color: Colors.orange,
        controls: [
            Controls.number({
                name: "count",
                label: "Count"
            })
        ]
    })
    .addPortType({
        type: "dialog",
        name: "dialog",
        label: "Dialog",
        color: Colors.green,
        controls: [
            Controls.text({
                name: "string",
                label: "Text"
            })
        ]
    })
    .addPortType({
        type: "then",
        name: "then",
        label: "Then",
        color: Colors.blue,
    })
    .addPortType({
        type: "boolean",
        name: "boolean",
        label: "Boolean",
        color: Colors.purple,
    })
    .addPortType({
        type: "success",
        name: "success",
        label: "Success",
        color: Colors.blue,
    })
    .addPortType({
        type: "failure",
        name: "failure",
        label: "Failure",
        color: Colors.blue,
    })
    .addNodeType({
        type: "dialog",
        label: "Dialog",
        description: "Player dialog",
        inputs: ports => (inputData, connections, context) => [
            ...portCaller(connections),
            ports.character(),
            ports.dialog()
        ],
        outputs: ports => [
            ports.then()
        ]
    })
    .addNodeType({
        type: "option",
        label: "DialogOption",
        description: "Player choice",
        inputs: ports => [
            ports.dialog()
        ],
        outputs: ports => [
            ports.then()
        ]
    })
    .addNodeType({
        type: "givetake",
        label: "Item",
        description: "Adds item to players inventory (includes money)",
        inputs: ports => (inputData, connections, context) => [
            ...portCaller(connections),
            ports.givetake(),
            ports.item(),
            ports.count(),
        ],
        outputs: ports => [
            {type: "then", name: "success", label: "Success"},
            {type: "then", name: "failure", label: "Failure"},
        ]
    })
    .addNodeType({
        type: "wait",
        label: "Wait",
        description: "Wait seconds",
        inputs: ports => (inputData, connections, context) => [
            ...portCaller(connections),
            ports.count(),
        ],
        outputs: ports => [
            ports.then(),
        ]
    })
    .addNodeType({
        type: "walk",
        label: "Walk",
        description: "Walk to waypoint",
        inputs: ports => (inputData, connections, context) => [
            ...portCaller(connections),
            ports.dialog(),
        ],
        outputs: ports => [
            ports.then(),
        ]
    })
    .addNodeType({
        type: "and",
        label: "And",
        description: "Checks if 2 conditions pass",
        inputs: ports => (inputData, connections, context) => [
            ...portCaller(connections),
            {type: "boolean", name: "a", label: "Condition 1"},
            {type: "boolean", name: "b", label: "Condition 2"},
        ],
        outputs: ports => [
            {type: "then", name: "success", label: "Success"},
            {type: "then", name: "failure", label: "Failure"},
        ]
    })
    .addNodeType({
        type: "goto",
        label: "Goto",
        description: "Jump to a different place in the graph",
        inputs: ports => (inputData, connections, context) => [
            ...portCaller(connections),
            ports.dialog(),
        ]
    })
    .addNodeType({
        type: "gotolabel",
        label: "Goto Destination",
        description: "Entry point that can be linked to via a Goto",
        inputs: ports => [
            ports.dialog()
        ],
        outputs: ports => [
            ports.then()
        ]
    })
    .addRootNodeType({
        type: "start",
        label: "Start",
        initialWidth: 170,
        outputs: ports => [
          ports.then(),
        ]
    });

export default config;

export const runFlume = () => {
    gamelogic.messages = [];
    let startNodes = Object.entries(scene.gamelogic).filter(([key, value]) => {
        return (value as any).type === 'start';
    });
    console.log(startNodes);
    doAction([{nodeId: startNodes[0][0]}]);
}

export const doAction = (nodeIds: {nodeId: string}[]) => {
    if (!nodeIds) return;
    for (const nodeId of nodeIds) {
        const node = scene.gamelogic[nodeId.nodeId];
        console.log('Action', nodeId, node);
        const fn = actions[node.type];
        if (fn) {
            fn(node.inputData, node.connections.outputs);
        } else {
            alert(`No logic for ${node.type}.`);
        }
    }
}

const actions = {
    dialog: async (inputs, outputs) => {
        const character = inputs.character.character === 'inherit' ? gamelogic.lastCharacter : inputs.character.character;
        gamelogic.lastCharacter = character;
        inputs.dialog.string.split('\n').forEach(message => {
            gamelogic.messages.push({
                message, character,
            });
        });
        doAction(outputs.then);
    },
    option: async (inputs, outputs) => {
        
    },
    givetake: async (inputs, outputs) => {
        doAction(outputs.success);
    },
    wait: async (inputs, outputs) => {

    },
    walk: async (inputs, outputs) => {

    },
    goto: async (inputs, outputs) => {

    },
    gotolabel: async (inputs, outputs) => {

    },
    start: async (inputs, outputs) => {
        doAction(outputs.then);
    },
};

class GameLogicStore {
    @observable lastCharacter = 'billy';
    @observable messages = [] as {message: string, character: string}[];

    constructor() {
        makeObservable(this)
    }
}
export const gamelogic = new GameLogicStore();
