import { Tool } from "./tool";
import { scene, store, state } from "../store";
import { geometry } from "../renderer/objects/geometry";
import { rerender } from "../renderer/renderer";

const GeometryTool: Tool = {
    name: '(G)eometry',
    icon: 'weekend',
    shortcut: 'g',
    onSelect: () => {
        const obj = geometry.create({});
        scene.children.push(obj);
        store.object = obj.id;
        state.object = obj;
        rerender();
    }
};

export { GeometryTool };
