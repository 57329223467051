import * as React from "react";
import { theme } from "../../theme";
import styled from "styled-components";
import { Item } from "../atoms/Item";
import { tools } from "../../tools/index";
import { scene, store, state } from "../../store";
import { observer } from "mobx-react";
import { ListArea, InputHolder, Input } from "../atoms/ObjectTree";

@observer
class ObjectPanel extends React.Component {
    state = {
        search: ''
    }
    render() {
        store.redraw;
        const { search } = this.state;
        return <Main>
            <InputHolder>
                <Input
                    id="objectsearch"
                    placeholder="(O)bjects..."
                    value={search}
                    onChange={(e) => {
                        this.setState({ search: e.currentTarget.value });
                    }}
                    onKeyUp={(e) => {
                        if (e.key === 'Escape') {
                            this.setState({ search: '' });
                            e.currentTarget.blur();
                        } else if (e.key === 'Enter') {
                            if (search) {
                                const firstObj = scene.children.filter((e) => {
                                    return !search || e.id.toLowerCase().replace(/[()]+/g, '').indexOf(search.toLowerCase()) !== -1;
                                })[0];
                                if (firstObj) {
                                    store.object = firstObj.id;
                                    state.object = firstObj;
                                }
                            }
                            this.setState({ search: '' });
                            e.currentTarget.blur();
                        }  
                    }}
                />
            </InputHolder>
            <ListArea>
                {doChildren(scene.children, search)}
            </ListArea>
        </Main>;
    }
}

function doChildren(children: any[], search: string) {
    if (!children) return;
    return children.filter((e) => {
        return !search || e.id.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }).map((e, i) => {
        return <div key={i}>
            <Item
                key={i}
                data={e}
                title={e.id}
                icon={""}
                fontSize="16px"
                selected={store.object===e.id}
                onClick={(e: any, data: any) => {
                    const obj = children.find((r) => r.id===data.id);
                    store.object = obj.id;
                    state.object = obj;
                }}
            />
            <div style={{ marginLeft: '5px' }}>
                {doChildren(e.children, search)}
            </div>
        </div>;
    });
}

const Main = styled.div`
    flex: 1;
    background: ${theme.paneColor};
    display: flex; flex-direction: column;
    overflow: hidden;
`;

export { ObjectPanel };
