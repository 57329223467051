import { SceneObject, uniqueId, baseProps } from "./object";
import { glInit, glDraw, vertSrc } from "../opengl";

const fragSrc = `
    precision mediump float;

    uniform sampler2D u_image;
    uniform vec2 u_sampleOffset;
    uniform float u_kernel[{{kernelSize}}];
    varying vec2 v_texCoord;

    void main() {
        vec4 colorSum = vec4(0,0,0,0);
        {{colorSum}}
        gl_FragColor = {{fragColor}};
    }
`;

// Applying the kernel filter to alpha only sometimes makes sense
// Blur is okay, but if applied to an outline shader, outlines for all color data would be hidden
var withAlpha = 'colorSum.rgba';
var withoutAlpha = 'vec4(colorSum.rgb, texture2D(u_image, v_texCoord + u_sampleOffset * vec2(0,0)).a)';

function setupShader({ quality, alpha }: { quality: number, alpha: boolean }) {
    console.log(quality);
    var matrixWidth = (quality*2) + 1;
    var r = quality;
    var colorSum = '';
	for (var y=0; y<matrixWidth; y++) {
		for (var x=0; x<matrixWidth; x++) {
			colorSum += 'colorSum += texture2D(u_image, v_texCoord + u_sampleOffset * vec2('+(x-r)+', '+(y-r)+')) * u_kernel['+((y*matrixWidth)+x)+'];\n';
		}
	}
	var fragSrcGood = fragSrc
		.replace('{{colorSum}}', colorSum)
		.replace('{{kernelSize}}', String(Math.pow(matrixWidth, 2)))
		.replace('{{fragColor}}', alpha?withAlpha:withoutAlpha);

    return fragSrcGood;
}

function gaussian(radius = 5) {
	var r = Math.ceil(radius);
	var kernelWidth = (r*2)+1;
	var r2 = 2*Math.pow(radius, 2);
    var weights = [];
    var total = 0;
	for (var x=0; x<kernelWidth; x++) {
		for (var y=0; y<kernelWidth; y++) {
            const val = (1/(Math.PI*r2)) * Math.exp(-(Math.pow(x-r,2)+Math.pow(y-r,2))/r2);
            weights.push(val);
            total += val;
		}
    }
    weights = weights.map((w) => w/total);
	return weights;
}

const kernelTypes: { [key: string]: number[] } = {
	sharpen: [
		-1, -1, -1,
		-1,  9, -1,
		-1, -1, -1
    ],
    outline: [
		-1, -1, -1,
		-1,  8, -1,
		-1, -1, -1
    ]
}

const kernel: SceneObject = {
    create: (props: any) => {
        return {
            id: (props.kernel || 'Kernel') + ' ' + uniqueId(),
            type: 'kernel',
            kernel: 'blur',
            alpha: false,
            radius: 10,
            quality: 1, // number of samples
            ...props
        };
    },
    render: async (ctx, data) => {
        let knl = kernelTypes[data.kernel];
        if (data.kernel === 'blur') {
            knl = gaussian(Number(data.quality) || 3);
        }
        const { width, height } = ctx.canvas;
       
        const [gl, shader]: any = glInit(ctx, data, setupShader);

        const matrixWidth = Math.floor(Math.pow(knl.length,0.5));
        const steps = Math.floor((matrixWidth-1)/2);

        // Pass data to fragment shader.
        // How far away (as a fraction of height/width) do we sample rgba values
        var sampleOffset = gl.getUniformLocation(shader, "u_sampleOffset");
        gl.uniform2f(sampleOffset, data.radius/width/steps, data.radius/height/steps);
        // The kernel matrix we want to use
        var kernelLocation = gl.getUniformLocation(shader, "u_kernel[0]");
        gl.uniform1fv(kernelLocation, knl);
        
        glDraw(gl, ctx, shader, width, height);
    }
};

export { kernel };
