import { Tool } from "./tool";
import { scene, store, state } from "../store";
import { pixelart } from "../renderer/objects/pixelart";
import { rerender } from "../renderer/renderer";

export const PixelArtTool: Tool = {
    name: 'Pixel Art (z)',
    icon: 'dialpad',
    shortcut: 'z',
    onSelect: () => {
        const obj = pixelart.create({});
        scene.children.push(obj);
        store.object = obj.id;
        state.object = obj;
        rerender();
    }
};
