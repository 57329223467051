import { computed, makeObservable, observable } from "mobx";
import { qr } from "./renderer/objects/qr";
import * as THREE from 'three';
import { threecamera, threepivot } from "./renderer/three";
import { tools } from "./tools";
import { Tool } from "./tools/tool";

/**
 * Stores application data which is observable by react components.
 */
class Store {
    @observable tool = '';
    @observable toolid = 0;
    @observable toolsettings = false; // Show tool properties?
    @observable showCursor = false;
    @observable gameLogicView = false;
    @observable object = '';
    @observable objectid = '';
    @observable property = '';
    @observable propertyIndex = 0;
    
    @observable _frame = 0;
    @observable frameStates = [] as {active: boolean}[];
    @computed get frame(): number {
        return this._frame;
    }
    set frame(value: number) {
        if (this.frameStates[value]) {
            this.frameStates[this._frame].active = false;
            this.frameStates[value].active = true;
            this._frame = value;
        }
    }
    
    @observable redraw = 1;

    @observable _mousePosition = [0,0];
    @observable _canvasPosition = [0,0];
    @computed get mousePosition(): number[] {
        return this._mousePosition;
    }
    @computed get canvasPosition(): number[] {
        return this._canvasPosition;
    }
    set mousePosition(value: number[]) {
        this._mousePosition = value;
        const canvasEle = document.getElementById('maincanvas')
        if (canvasEle) {
            const canvas = canvasEle.getBoundingClientRect();
            this._canvasPosition = [(value[0]-canvas.left)*scene.scale, (value[1]-canvas.top)*scene.scale];
        }
    }

    @observable playing = false;
    @observable playingStart = Date.now();
    @observable dragging = false;
    @observable rightdragging = false;

    @observable colorDragging = 0;
    @observable picker = false;
    @observable selectedPaletteColor = 0;
    
    @observable showPanels = true;
    @observable rendering = false;

    @observable canvasRotation = 0;

    @observable browserSize = [window.innerWidth, window.innerHeight];

    constructor() {
        makeObservable(this)
    }
}
const store = new Store();
store.frameStates = new Array(240).fill({active: false}, 0, 240);
store.frameStates[0].active = true;

const state = ({
    get tool() {
        return tools[store.toolid];
    },
    set tool(tid: Tool) {
        store.toolid = tools.indexOf(tid);
    },
    get object() {
        return scene.children.find((ch) => ch.id === store.objectid);
    },
    set object(oid: any) {
        store.objectid = oid.id;
    },
});

const scene = observable({
    scale: 1,
    dimensions: [640, 360],
    backgroundColor: 'rgb(224,224,224)',
    palettes: [['#6affbe', '#ff7e6a', '#ffc86a', '#946aff']],
    children: [] as { [key: string]: any }[],
    sidebarWidth: 450,
    gamelogic: {},
});

function touchMove(e: any) {
    if (e.touches && e.touches[0]) e = e.touches[0];
    const mouseDelta = [e.clientX-store.mousePosition[0], e.clientY-store.mousePosition[1]];
    store.mousePosition = [e.clientX, e.clientY];
    if (state.tool && state.tool.onMouseMove) {
        state.tool.onMouseMove(mouseDelta);
    }
    if (store.rightdragging) {
        threepivot.rotateOnWorldAxis(new THREE.Vector3(0,1,0), mouseDelta[0]/500);
        threepivot.rotateX(-mouseDelta[1]/500);
    }
}
document.body.addEventListener('touchstart', touchMove);
document.body.addEventListener('mousemove', touchMove);
document.body.addEventListener('touchmove', (e) => {
    if (store.dragging) e.preventDefault();
    touchMove(e)
}, { passive: false });
function touchEnd(e: any) {
    if (state.tool && state.tool.onMouseUp) {
        state.tool.onMouseUp();
    }
    store.dragging = false;
    store.rightdragging = false;
    store.colorDragging = 0;
}
document.body.addEventListener('mouseup', touchEnd);
document.body.addEventListener('touchend', touchEnd, { passive: false });
document.body.addEventListener('contextmenu', (e) => e.preventDefault());
window.addEventListener('resize', () => {
    store.browserSize = [window.innerWidth, window.innerHeight];
});
window.addEventListener('deviceorientation', () => {
    store.browserSize = [window.innerWidth, window.innerHeight];
});


export { store, state, scene };
