import * as React from "react";
import { theme } from "../../theme";
import styled from "styled-components";
import { Item } from "../atoms/Item";
import { tools } from "../../tools/index";
import { scene, store, state } from "../../store";
import { observer } from "mobx-react";
import { ListArea, InputHolder, Input } from "../atoms/ObjectTree";
import { rerender } from "../../renderer/renderer";

var leftPos = [0,0];
var rightPos = 0;
var rainbow = [255,0,0];
var color = { red: 0, green: 0, blue: 0, alpha: 255 };

var stops = [
	[255,0,0],
	[255,0,255],
	[0,0,255],
	[0,255,255],
	[0,255,0],
	[255,255,0],
	[255,0,0]
];

function clamp(x: number, a: number, b: number) {
	return Math.max(a, Math.min(x, b))
}

@observer
class Picker extends React.Component {
    picker: HTMLCanvasElement;
    componentDidMount() {
        this.refreshCanvas();
    }
    refreshCanvas = () => {
        const ctx = this.picker.getContext('2d');

        var grd = ctx.createLinearGradient(0,0,0,256);
        stops.forEach((stop, i) => {
            grd.addColorStop(i/(stops.length-1),`rgb(${stop.join(',')})`);
        });
        ctx.fillStyle = grd;
        ctx.fillRect(0,0,44,256);

        grd = ctx.createLinearGradient(44,0,286,0);
        grd.addColorStop(0,"rgba(255,255,255,1)");
        grd.addColorStop(1,"rgba("+rainbow[0]+','+rainbow[1]+','+rainbow[2]+",1)");
        ctx.fillStyle = grd;
        ctx.fillRect(44,0,256,256);
        
        grd = ctx.createLinearGradient(0,0,0,256);
        grd.addColorStop(0,"rgba(0,0,0,0)");
        grd.addColorStop(1,"rgba(0,0,0,1)");
        ctx.fillStyle = grd;
        ctx.fillRect(44,0,256,256);

        grd = ctx.createLinearGradient(0,0,300,0);
        grd.addColorStop(0,"rgb(255,255,255)");
        grd.addColorStop(1,"rgb("+color.red+','+color.green+','+color.blue+",1)");
        ctx.fillStyle = grd;
        ctx.fillRect(0,256,300,44);
    }
    touch = () => {
        if (!store.colorDragging) return false;

        const cr = this.picker.getBoundingClientRect();
        const pos = [ store.mousePosition[0]-cr.left, store.mousePosition[1]-cr.top ];
        if (store.colorDragging === 1) {
            if (pos[1] > 256) {
                store.colorDragging = 2;
            } else if (pos[0] > 44) {
                store.colorDragging = 3;
            } else {
                store.colorDragging = 4;
            }
        }

        if (store.colorDragging === 2) {
            color.alpha = clamp(pos[0], 0, 300);
        } else if (store.colorDragging === 3) {
            leftPos = pos;
            leftPos[0] = clamp(leftPos[0]-30, 0, 255);
            leftPos[1] = clamp(leftPos[1], 0, 255);
        } else {
            rightPos = pos[1];
            rightPos = clamp(rightPos, 0, 255);
        }
    
        var t = Math.floor(6*(rightPos/256));
        var p = (rightPos - (t*256/6)) / (256/6);
        color.red = Math.floor((stops[t][0]*(1-p)) + (stops[t+1][0]*p));
        color.green = Math.floor((stops[t][1]*(1-p)) + (stops[t+1][1]*p));
        color.blue = Math.floor((stops[t][2]*(1-p)) + (stops[t+1][2]*p));
        rainbow = [color.red, color.green, color.blue];
    
        color.red += (255-color.red) * (1-(leftPos[0]/255));
        color.green += (255-color.green) * (1-(leftPos[0]/255));
        color.blue += (255-color.blue) * (1-(leftPos[0]/255));
    
        color.red = Math.floor(color.red*(1-(leftPos[1]/255)));
        color.green = Math.floor(color.green*(1-(leftPos[1]/255)));
        color.blue = Math.floor(color.blue*(1-(leftPos[1]/255)));
        
        const colorString = `rgba(${color.red},${color.green},${color.blue},${(color.alpha/300).toFixed(2)})`;

        scene.palettes[0][store.selectedPaletteColor] = colorString;
        store.selectedPaletteColor = store.selectedPaletteColor + 1;
        store.selectedPaletteColor = store.selectedPaletteColor - 1;

        if (store.toolsettings && state.tool.settings[store.property] !== undefined) {

        } else {
            if (Array.isArray(state.object[store.property])) {
                state.object[store.property][store.propertyIndex] = colorString;
            } else {
                state.object[store.property] = colorString;
            }
        }
        
        rerender();
        this.refreshCanvas();
    }
    render() {
        return <Main
            style={{ display: store.picker ? undefined : 'none' }}
            onMouseDown={(e)=>{
                e.preventDefault();
            }}
            onMouseMove={(e)=>{
                e.preventDefault();
                this.touch();
            }}
            onMouseUp={(e)=>e.preventDefault()}
        >
            <Palette>
                {scene.palettes[0].map((e, i) => {
                    return <div
                        key={i}
                        style={{
                            width: '18px', height: '18px', backgroundColor: e,
                            borderColor: store.selectedPaletteColor === i ? 'white' : 'black',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                        }}
                        onClick={() => {
                            store.selectedPaletteColor = i;
                            if (store.toolsettings && state.tool.settings[store.property] !== undefined) {
                                state.tool.settings[store.property] = i;
                            } else {
                                if (Array.isArray(state.object[store.property])) {
                                    state.object[store.property][store.propertyIndex] = scene.palettes[0][store.selectedPaletteColor];
                                } else {
                                    state.object[store.property] = scene.palettes[0][store.selectedPaletteColor];
                                }
                            }
                            rerender();
                        }}
                    />;
                })}
                <i onClick={() => {
                    scene.palettes[0].push('red');
                    store.selectedPaletteColor = scene.palettes[0].length - 1;
                    rerender();
                }} style={{
                    width: '18px', height: '18px', backgroundColor: 'black',
                    borderColor: 'black',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    color: 'white', fontSize: '18px', cursor: 'pointer'
                }} className="material-icons">{'library_add'}</i>
            </Palette>
            <canvas
                ref={(e)=>this.picker=e}
                width="300" height="300"
                onMouseDown={(e)=>{
                    e.preventDefault();
                    store.colorDragging = 1;
    
                    this.touch();
                }}
            />
        </Main>;
    }
}

const Main = styled.div`
    position: absolute;
    top: 50%; left: 50%; width: 320px; height: 345px;
    margin-left: -160px; margin-top: -138px;
    padding: 10px; box-sizing: border-box;
    background: ${theme.paneColor};
`;

const Palette = styled.div`
    display: flex; flex-direction: 'row'; height: 25px;
`;

export { Picker };
