import { store, state } from "../store";
import { Tool } from "../tools/tool";

/**
 * Chooses the active tool in the UI's left column.
 * @param tool The tool you would like to be active
 * 
 * @example
 * setTool({ name: 'Draw' });
 */
function setTool(tool: Tool) {
    let changeTool = true;
    store.toolsettings = false;
    store.showCursor = false;
    if (tool.onSelect) {
        changeTool = tool.onSelect();
    }
    if (changeTool) {
        store.tool = tool.name;
        state.tool = tool;
    }
}

export { setTool };
