import { Tool } from "./tool";
import { scene, store, state } from "../store";
import { kernel } from "../renderer/objects/kernel";
import { rerender } from "../renderer/renderer";

const BlurTool: Tool = {
    name: 'Blur Filter (y)',
    icon: 'blur_on',
    shortcut: 'y',
    onSelect: () => {
        const obj = kernel.create({ kernel: 'blur', alpha: true, quality: 3 });
        scene.children.push(obj);
        store.object = obj.id;
        state.object = obj;
        rerender();
        return false;
    }
};

export { BlurTool };
