import { Tool } from "./tool";
import { store, state, scene } from "../store";
import { qr } from "../renderer/objects/qr";
import { line } from "../renderer/objects/line";
import { rerender } from "../renderer/renderer";

const DrawTool: Tool = {
    name: '(D)raw',
    icon: 'create',
    shortcut: 'd',
    onMouseDown: () => {
        const [x, y] = store.canvasPosition;
        const ln = line.create({
            points: [[x, y]],
            _bounds: [x, y, x, y],
        });
        scene.children.push(ln);
        store.object = ln.id;
        state.object = ln;
        rerender();
    },
    onMouseMove: ([dx, dy]: number[]) => {
        if (store.dragging) {
            const [lastx, lasty] = state.object.points[state.object.points.length-1];
            const [x, y] = store.canvasPosition;
            if (Math.abs(lastx-x) > 3 || Math.abs(lasty-y) > 3) {
                state.object.points.push([x, y]);
                state.object._bounds = [
                    Math.min(x, state.object._bounds[0]),
                    Math.min(y, state.object._bounds[1]),
                    Math.max(x, state.object._bounds[2]),
                    Math.max(y, state.object._bounds[3]),
                ];
                rerender();
            }
        }
    },
    onMouseUp: () => {
        if (store.dragging) {
            // Move pivot to center of object
            const center = [
            (state.object._bounds[0]+state.object._bounds[2])/2,
            (state.object._bounds[1]+state.object._bounds[3])/2,
            ];
            state.object.points = state.object.points.map((p: number[]) => {
                return [
                    p[0] - center[0],
                    p[1] - center[1],
                ];
            });
            state.object.translate = center;
            rerender();
        }
    }
};

export { DrawTool };
