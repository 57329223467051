import * as React from "react";
import { SceneObject, uniqueId, baseProps } from "./object";
import { rect } from "./rect";
import { scene, store } from "../../store";
import styled from "styled-components";
import { observer } from "mobx-react";
import * as math from "mathjs";
const QRCode = require('qrcode');

function hexToRgb(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
        return {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
            a: 255,
        };
    }
    var result = /\(([\d]).+([\d]).+([\d]).+\)/i.exec(hex);
    if (result) {
        return {
            r: Number(result[1]),
            g: Number(result[2]),
            b: Number(result[3]),
            a: 255,
        };
    }
    return null;
}

function exportSpriteSheet(props: any) {
    const size = Math.ceil(Math.sqrt(props.pixels.length));

    const canvas = document.createElement('canvas');
    canvas.width = 16 * size; canvas.height = 16 * size;
    const cty = canvas.getContext('2d');

    const torender = new Array(props.pixels.length).fill(0).map((e,i)=>i)

    const imgdata = cty.getImageData(0,0,16*size,16*size);
    torender.forEach((frame, i) => {
        const pixelSet = props.pixels[frame];
        if (!pixelSet) return;
        for (var y=0; y<pixelSet.length; y++) {
            const row = pixelSet[y];
            for (var x=0; x<row.length; x++) {
                const spriteX = (i%size) * 16;
                const spriteY = Math.floor(i/size) * 16;
                const v = row[x];
                const c = hexToRgb(scene.palettes[0][v]);
                const idx = ((y+spriteY)*(16*size) + (x+spriteX)) * 4;
                if (c) {
                    imgdata.data[idx] = c.r;
                    imgdata.data[idx+1] = c.g;
                    imgdata.data[idx+2] = c.b;
                    imgdata.data[idx+3] = c.a;
                } else {
                    imgdata.data[idx] = 0;
                    imgdata.data[idx+1] = 0;
                    imgdata.data[idx+2] = 0;
                    imgdata.data[idx+3] = 0;
                }
            }
        }
    });
    cty.putImageData(imgdata, 0, 0);

    const a = document.createElement('a');
    a.setAttribute('download', 'download');
    a.href = canvas.toDataURL();
    a.click();
}

const FrameIcon = styled.div`
    background: white; margin: 1px;
    width: 20px; height: 20px; text-align: center; line-height: 20px;
    cursor: pointer;
`;

export const pixelart: SceneObject = {
    controls: observer(({props}: any) => {
        return <div>
            <div onClick={() => {
                exportSpriteSheet(props);
            }}>{'Export Sprite Sheet'}</div>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                {props && props.pixels.map((e: any[], i: number) => {
                    return <FrameIcon onClick={() => {
                        props['frame'] = i;
                    }}>{i}</FrameIcon>;
                })}
                <FrameIcon onClick={() => {
                    props['pixels'].push(new Array(16).fill(null).map((a, i) => (
                        new Array(16).fill(null).map((b, j) => ((i+j)%4)-1
                    ))));
                }}>{'+'}</FrameIcon>
            </div>
        </div>;
    }),
    create: (props: any) => {
        return {
            id: 'PixelArt ' + uniqueId(),
            type: 'pixelart',
            frame: 0,
            spritesheet: 0,
            dimensions: [16, 16],
            pixels: [new Array(16).fill(null).map((a, i) => (
                new Array(16).fill(null).map((b, j) => ((i+j)%4)-1
            )))],
            color: 'clear',
            red: '',
            green: '',
            blue: '',
            ...baseProps(),
            ...props
        };
    },
    render: async (ctx, data) => {
        let {pixels,frame,spritesheet,red:_red,green:_green,blue:_blue} = data;

        // Auto create extra frames
        // if (!pixels[frame]) {
        //     const lastF = pixels[pixels.length-1];
        //     const newF = lastF.map((r: any[]) => [...r]);
        //     pixels[frame] = newF;
        // }

        if (!pixels) return ctx;
        const torender = Number(spritesheet) ? new Array(pixels.length).fill(0).map((e,i)=>i) : [Math.floor(Number(frame))];

        // Option 1

        // const canvas = document.createElement('canvas');
        // canvas.width = 16; canvas.height = 16;
        // const cty = canvas.getContext('2d');

        // const imgdata = cty.getImageData(0,0,16,16);
        // torender.forEach((frame, i) => {
        //     const pixelSet = pixels[frame];
        //     if (!pixelSet) return;
        //     for (var y=0; y<pixelSet.length; y++) {
        //         const row = pixelSet[y];
        //         for (var x=0; x<row.length; x++) {
        //             const v = row[x];
        //             const c = hexToRgb(scene.palettes[0][v]);
        //             const idx = (y*16 + x) * 4;
        //             if (c) {
        //                 imgdata.data[idx] = c.r;
        //                 imgdata.data[idx+1] = c.g;
        //                 imgdata.data[idx+2] = c.b;
        //                 imgdata.data[idx+3] = c.a;
        //             } else {
        //                 imgdata.data[idx] = 0;
        //                 imgdata.data[idx+1] = 0;
        //                 imgdata.data[idx+2] = 0;
        //                 imgdata.data[idx+3] = 0;
        //             }
        //         }
        //     }
        // });
        // cty.putImageData(imgdata, 0, 0);

        // const canvas3 = document.createElement('canvas');
        // canvas3.width = 320; canvas3.height = 320;
        // const ctz = canvas3.getContext('2d');
        // ctz.imageSmoothingEnabled = false;
        // ctz.scale(20, 20);
        // ctz.drawImage(canvas, 0, 0);

        // ctx.save();
        // ctz.scale(0.5, 0.5);
        // ctx.drawImage(canvas3, 0, 0);
        // ctx.restore();

        // Option 2

        torender.forEach((frame, i) => {
            const pixelSet = pixels[frame];
            if (!pixelSet) return;
            for (var y=0; y<pixelSet.length; y++) {
                const row = pixelSet[y];
                for (var x=0; x<row.length; x++) {
                    const v = row[x];
                    let red = _red; let green = _green; let blue = _blue;
                    if (red !== "" || green !== "" || blue !== "") {
                        if (red[0] === '=') {
                            try {
                                red = math.eval(red.slice(1), {x, y, frame: store.frame, time: store.frame/30, unixtime: Date.now()/1000});
                            } catch {}
                        }
                        if (green[0] === '=') {
                            try {
                                green = math.eval(green.slice(1), {x, y, frame: store.frame, time: store.frame/30, unixtime: Date.now()/1000});
                            } catch {}
                        }
                        if (blue[0] === '=') {
                            try {
                                blue = math.eval(blue.slice(1), {x, y, frame: store.frame, time: store.frame/30, unixtime: Date.now()/1000});
                            } catch {}
                        }
                        red = isNaN(Number(red)) ? 0 : Number(red);
                        green = isNaN(Number(green)) ? 0 : Number(green);
                        blue = isNaN(Number(blue)) ? 0 : Number(blue);
                        ctx.fillStyle = `rgb(${red},${green},${blue})`;
                    } else {
                        ctx.fillStyle = scene.palettes[0][v];
                    }
                    if (v >= 0 || red || green || blue) {
                        ctx.fillRect(x*20 + i*20*16, y*20, 20, 20);
                    }
                }
            }
        });
        return ctx;
    }
};
