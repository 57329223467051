import { Tool } from "./tool";
import { scene, store, state } from "../store";
import { kernel } from "../renderer/objects/kernel";
import { rerender } from "../renderer/renderer";

const SharpenTool: Tool = {
    name: 'S(h)arpen Filter',
    icon: 'blur_on',
    shortcut: 'h',
    onSelect: () => {
        const obj = kernel.create({ kernel: 'sharpen' });
        scene.children.push(obj);
        store.object = obj.id;
        state.object = obj;
        rerender();
        return false;
    }
};

export { SharpenTool };
